<template>
  <!-- <i class="svgicon" v-html="icon_path" aria-hidden="true"></i> -->
  <i class="svgicon" aria-hidden="true">
    <img v-svg-inline :src="icon_path" alt="">
  </i>

</template>

<script>
export default {
  props: ['icon'],
  name: 'SvgIcon',

  data () {
    return {
     //icon_path : require(`!html-loader!@/assets/icons/${this.icon}.svg`)
     icon_path : require(`@/assets/icons/${this.icon}.svg`)
    }
  },
}
</script>

<style >

.svgicon {
    text-align: center;
    line-height: 0;
    outline: none;
}
.svgicon svg {
  width: 100%;
  height: auto;
  outline: none;
}
</style>
